import React, { useEffect, useState } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { Calendar, Clock, MapPin, Phone, Mail, Building, Zap, Image } from 'lucide-react'
export default function SpicyPowerPlantInfo() {
  const [scrollY, setScrollY] = useState(0)
  const { scrollYProgress } = useScroll()

  const backgroundColor = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    ["#4ade80", "#22c55e", "#15803d"]
  )

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <motion.div 
      className="min-h-screen"
      style={{ backgroundColor }}
    >
      <div 
        className="h-[70vh] bg-cover bg-bottom bg-fixed relative overflow-hidden"
        style={{
          backgroundImage: "url(./BildFirma.jpeg)",
          transform: `translateY(${scrollY * 0.5}px)`
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-40" />
        <div className="absolute inset-0 flex items-center justify-center">
    
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl md:text-6xl font-bold text-white text-center"
          >
            {"Eröffnung des Heizwerk Kirchbach".split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.05 }}
              >
                {char}
              </motion.span>
            ))}
          </motion.h1>
        </div>
        <motion.div
          className="absolute inset-0 pointer-events-none"
          initial={{ backgroundPosition: '0 0' }}
          animate={{ backgroundPosition: '100% 100%' }}
          transition={{ repeat: Infinity, duration: 20, ease: 'linear' }}
          style={{
            backgroundImage: 'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" fill="%23ffffff" fill-opacity="0.1" fill-rule="evenodd"/%3E%3C/svg%3E")',
            backgroundSize: '400px 400px',
          }}
        />
      </div>

      <motion.div 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="container mx-auto px-4 py-12 -mt-40"
      >
        <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-xl shadow-xl p-8 max-w-4xl mx-auto">
   
          <h2 className="text-3xl font-semibold mb-6 text-green-800">Einladung zur Eröffnung</h2>
          <motion.div 
            className="flex items-center mb-6"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <Calendar className="w-6 h-6 mr-2 text-green-600" />
            <p className="text-xl">Samstag, 26. Oktober 2024</p>
          </motion.div>
          
          <motion.div 
            className="flex items-center mb-6"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <MapPin className="w-6 h-6 mr-2 text-green-600" />
            <p className="text-xl">Kirchbach 327</p>
          </motion.div>

          <h3 className="text-2xl font-semibold mb-4 text-green-700">Programm:</h3>
          <ul className="space-y-4 mb-8">
            {[
              { time: "09:30", event: "Empfang mit Umrahmung durch die MMK Kirchbach" },
              { time: "10:00", event: "Feierliche Eröffnung mit LR Simone Schmiedtbauer" },
              { time: "Anschließend", event: "Segnung durch Pfarrer GR Mag. Christian Grabner, „Tag der offenen Tür' mit Führungen" }
            ].map((item, index) => (
              <motion.li 
                key={index}
                className="flex items-start p-2 rounded-lg hover:bg-green-100 transition-colors duration-200"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 + 0.8 }}
              >
                <Clock className="w-5 h-5 mr-2 mt-1 text-green-600" />
                <span><strong>{item.time}</strong> - {item.event}</span>
              </motion.li>
            ))}
          </ul>

          <motion.p 
            className="text-lg mb-8 italic"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2 }}
          >
            Für Ihr leibliches Wohl ist gesorgt.
          </motion.p>
          <h3 className="text-2xl font-semibold mb-4 text-green-700">Kontakt:</h3>
          <div className='flex justify-between'>
          <div className="space-y-2">
            {[
              { icon: Building, text: "Nahwärmeversorgung Kirchbach eGen" },
              { icon: null, text: "Josef Uller und Franz Josef Paier" },
              { icon: MapPin, text: "Breitenbuch 45, 8082 Kirchbach" },
              { icon: Phone, text: "<a class='text-green-700 underline' href='tel:06649635971'>0664/9635971</a> od. <a class='text-green-700 underline' href='tel:06643021876'>06643021876</a>" },
              { icon: Mail, text: "<a class='text-green-700 underline' href='mailto:info@nw-kirchbach.at'>info@nw-kirchbach.at</a>" }
            ].map((item, index) => (
              <motion.p 
                key={index}
                className="flex items-center"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 + 1.4 }}
                whileHover={{ scale: 1.05, color: "#059669" }}
              >
                {item.icon && <item.icon className="w-5 h-5 mr-2 text-green-600" />}
                <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
                </motion.p>
            ))}
          </div>
                     <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-8"
          >
            <img
              src="./logo.png"
              alt="Nahwärmeversorgung Kirchbach Logo"
              width={400}
              height={100}
              className="max-w-full h-auto"
            />
          </motion.div>
          </div>

 
            <motion.div 
            className="mt-8 text-sm text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2 }}
          >
            <p>FN: 155749v; FB-Gericht: LG f. ZRS Graz</p>
            <p>UID: ATU60575602</p>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  )
}